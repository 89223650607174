import React from "react";



export default function ContactMap() {

    return (
        <section className="map-section" data-inview-showup="showup-translate-right">


        <div className="gmap" data-lat="-33.8670" data-lng="151.2045"></div>
        <div className="info-wrap">
            <div className="info-container">
                <div className="our-info side main-bg">
                    <div className="info-block">
                        <div className="info-title text-upper">Contact Us</div>
                        
                        <div className="info-line"><span className="info-icon"><i className="far fa-envelope fa-fw"
                                    aria-hidden="true"></i></span><a href='mailto:help@mrxpress.com.au' style={{ 
                                        textDecoration: 'none', 
                                        color: 'inherit',
                                        '&:hover': {
                                          textDecoration: 'none',
                                          color: 'inherit'
                                        }
                                      }}>help@mrxpress.com.au</a></div>
                        <div className="info-line"><span className="info-icon"></span><i className="fas fa-phone-alt"></i> <a href='tel:+610411937372' style={{ 
                    textDecoration: 'none', 
                    color: 'inherit',
                    '&:hover': {
                      textDecoration: 'none',
                      color: 'inherit'
                    }
                  }}>0411 93 73 72</a> </div>
                    </div>
                    <div className="info-block">
                        <div className="info-title text-upper">Opening Hours</div>
                        <div className="info-line">Monday-Sunday<span className="pull-right">9:00 am - 6:00 pm</span></div>
                    </div>
                </div>
            </div>
        </div>

    </section>
    )
}