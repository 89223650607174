import React from "react";
import Footer from "../components/Footer";
import ContactMap from "../components/ContactMap";
import FooterInit from "../components/FooterInit";
import HeaderInit from "../components/HeaderInit";
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from "@mui/material";


export default function LocationService() {

    const params = useParams();
    const [locName, setLocName] = React.useState('');
    const curUrl = React.useRef(null);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = React.useState('');
    const [suggestions, setSuggestions] = React.useState([]);

    const locationMap = {
        "Point Piper": "/Locations/Point-Piper",
        "Double Bay": "/Locations/Double-Bay",
        "Darling Point": "/Locations/Darling-Point",
        "Vaucluse": "/Locations/Vaucluse",
        "Bellevue Hill": "/Locations/Bellevue-Hill",
        "Watsons Bay": "/Locations/Watsons-Bay",
        "Edgecliff": "/Locations/Edgecliff",
        "Rose Bay": "/Locations/Rose-Bay",
        "Dover Heights": "/Locations/Dover-Heights",
        "Bondi Beach": "/Locations/Bondi-Beach",
        "Mosman": "/Locations/Mosman",
        "Cremorne": "/Locations/Cremorne",
        "Cremorne Point": "/Locations/Cremorne-Point",
        "Neutral Bay": "/Locations/Neutral-Bay",
        "North Sydney": "/Locations/North-Sydney",
        "Kirribilli": "/Locations/Kirribilli",
        "McMahons Point": "/Locations/McMahons-Point",
        "Balmain": "/Locations/Balmain",
        "Birchgrove": "/Locations/Birchgrove",
        "Rozelle": "/Locations/Rozelle",
        "Hunters Hill": "/Locations/Hunters-Hill",
        "Turramurra": "/Locations/Turramurra",
        "Wahroonga": "/Locations/Wahroonga",
        "Killara": "/Locations/Killara",
        "Campsie": "/Locations/Campsie",
        "Gordon": "/Locations/Gordon",
        "Roseville": "/Locations/Roseville",
        "Lindfield": "/Locations/Lindfield",
        "Mascot": "/Locations/Mascot",
        "Burwood": "/Locations/Burwood",
        "Woollahra": "/Locations/Woollahra",
        "Paddington": "/Locations/Paddington",
        "Potts Point": "/Locations/Potts-Point",
        "Surry Hills": "/Locations/Surry-Hills",
        "Chippendale": "/Locations/Chippendale",
        "Ultimo": "/Locations/Ultimo",
        "Balmain East": "/Locations/Balmain-East",
        "Leichhardt": "/Locations/Leichhardt",
        "Petersham": "/Locations/Petersham",
        "Stanmore": "/Locations/Stanmore",
        "Summer Hill": "/Locations/Summer-Hill",
        "Haberfield": "/Locations/Haberfield",
        "Drummoyne": "/Locations/Drummoyne",
        "Gladesville": "/Locations/Gladesville",
        "Parramatta": "/Locations/Parramatta",
        "Roselands": "/Locations/Roselands",
        "Ashfield": "/Locations/Ashfield",
        "Cabramatta": "/Locations/Cabramatta",
        "Marrickville": "/Locations/Marrickville",
        "Carlingford": "/Locations/Carlingford",
        "Epping": "/Locations/Epping",
        "Northmead": "/Locations/Northmead",
        "Alexandria": "/Locations/Alexandria",
        "Norwest": "/Locations/Norwest",
        "Pennant Hills": "/Locations/Pennant-Hills",
        "Hornsby": "/Locations/Hornsby",
        "St Ives": "/Locations/St-Ives",
        "Chatswood": "/Locations/Chatswood",
        "Artarmon": "/Locations/Artarmon",
        "Crows Nest": "/Locations/Crows-Nest",
        "Enmore": "/Locations/Enmore",
        "Waverton": "/Locations/Waverton",
        "Wollstonecraft": "/Locations/Wollstonecraft",
        "Sydney Olympic Park": "/Locations/Sydney-Olympic-Park",
        "Lane Cove": "/Locations/Lane-Cove",
        "Pyrmont": "/Locations/Pyrmont",
        "Redfern": "/Locations/Redfern",
        "Waverley": "/Locations/Waverley",
        "Bondi": "/Locations/Bondi",
        "Bronte": "/Locations/Bronte",
        "Tamarama": "/Locations/Tamarama",
        "North Bondi": "/Locations/North-Bondi",
        "Glebe": "/Locations/Glebe",
        "Clovelly": "/Locations/Clovelly",
        "Coogee": "/Locations/Coogee",
        "Randwick": "/Locations/Randwick",
        "Kensington": "/Locations/Kensington",
        "Maroubra": "/Locations/Maroubra",
        "Cronulla": "/Locations/Cronulla",
        "Caringbah": "/Locations/Caringbah",
        "Newtown": "/Locations/Newtown",
        "Sylvania": "/Locations/Sylvania",
        "Miranda": "/Locations/Miranda",
        "Kirrawee": "/Locations/Kirrawee",
        "Caringbah South": "/Locations/Caringbah-South",
        "Hurstville": "/Locations/Hurstville",
        "Oatley": "/Locations/Oatley",
        "Mortdale": "/Locations/Mortdale",
        "Penshurst": "/Locations/Penshurst",
        "Beverly Hills": "/Locations/Beverly-Hills",
        "Sans Souci": "/Locations/Sans-Souci",
        "Rockdale": "/Locations/Rockdale",
        "Kogarah": "/Locations/Kogarah",
        "Banksia": "/Locations/Banksia",
        "Bexley": "/Locations/Bexley",
        "Peakhurst": "/Locations/Peakhurst",
        "Hurstville Grove": "/Locations/Hurstville-Grove",
        "Peakhurst Heights": "/Locations/Peakhurst-Heights",
        "Beverley Park": "/Locations/Beverley-Park",
        "Five Dock": "/Locations/Five-Dock",
        // Add more locations as needed
    };

    React.useEffect(() => {
        var paramLocName = params.locName;
        if (paramLocName) {
            paramLocName = params.locName.replace(/-/g, ' ').charAt(0).toUpperCase() + params.locName.replace(/-/g, ' ').slice(1)
        }
        setLocName(paramLocName ? paramLocName : '');
        // setLocName(params.locName.charAt(0).toUpperCase() + params.locName.slice(1))
        curUrl.current = window.location.href;
    }, []);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        if (value) {
            const filteredSuggestions = Object.keys(locationMap).filter(location =>
                location.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions.length > 0 ? filteredSuggestions : ["Please contact us to confirm"]);
        } else {
            setSuggestions([]);
        }
    };

    const handleBookNow = () => {
        navigate('/repair');
    };

    const handleSuggestionClick = (location) => {
        if (location !== "Please contact us to confirm") {
            window.location.href = locationMap[location];
        }
    };

    return (
        <>
        <Helmet>
            <title>{`${locName} Mobile Phone Repairs | MR Xpress`}</title>
            <meta name="description" content="Get in touch with MR Xpress for all your mobile phone repair inquiries. Our friendly team is here to assist you with booking and service questions." />
            <meta name="keywords" content={`${locName}, phone repair, MR Xpress`} />
            <meta property="og:title" content={`${locName} Mobile Phone Repairs | MR Xpress`} />
            <meta property="og:description" content="Get in touch with MR Xpress for all your mobile phone repair inquiries. Our friendly team is here to assist you with booking and service questions." />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="MR Xpress"></meta>
            <meta property="og:url" content={curUrl.current} />
            <link rel="canonical" href={curUrl.current} />
            <meta name="robots" content="index,follow"></meta>
            <meta name="googlebot" content="index,follow"></meta>
            {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "LocationPage",
                "name": locName + " Mobile Phone Repairs | MR Xpress",
                "description": "Get in touch with MR Xpress for all your mobile phone repair inquiries. Our friendly team is here to assist you with booking and service questions.",
                "url": curUrl.current,
                "mainEntity": {
                    "@type": "Organization",
                    "name": "MR Xpress",
                    "url": curUrl.current,
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "telephone": "+61-411-937-372",
                        "contactType": "Customer Support"
                    }
                },
            })}
        </Helmet>
        <div style={{ width: '100%', }}>
            <HeaderInit />
            <section className="with-bg solid-section">

                <div className="theme-back"></div>
                <div className="container page-info">

                    <div className="section-alt-head container-md">

                        <h1 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right">
                            {locName ? (
                                <span>
                                We are servicing{' '}
                                <span style={{
                                    borderBottom: '3px solid #29abe2',
                                    paddingBottom: '2px',
                                    transition: 'border-color 0.3s ease'
                                }}>
                                    {locName}
                                </span>
                                {' '}area
                                </span>
                            ) : "MR Xpress Service Locations"}
                        </h1>

                        <p data-inview-showup="showup-translate-left"></p>
                    </div>


                </div>

                <div className="section-footer">
                    <div className="container" data-inview-showup="showup-translate-down">
                        <ul className="page-path">

                            <li><a href="index.html">Home</a></li>


                            <li className="path-separator"><i className="fas fa-chevron-right" aria-hidden="true"></i></li>

                            <li>{locName ? `${locName} Mobile Phone Repairs` : "MR Xpress Service Locations"}</li>

                        </ul>
                    </div>
                </div>

            </section>
            <section className="content-section">
                <div className="container">
                    <div className="search-box" style={{ position: 'relative', float: 'right', marginBottom: '20px' }}>
                        <input
                            type="text"
                            placeholder="Search for a location..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            style={{ padding: '10px', width: '210px' }}
                        />
                        {suggestions.length > 0 && (
                            <ul style={{ position: 'absolute', top: '40px', right: '0', backgroundColor: 'white', border: '1px solid #ccc', listStyle: 'none', padding: '0', margin: '0', width: '210px' }}>
                                {suggestions.map((suggestion, index) => (
                                    <li
                                        key={index}
                                        onClick={() => handleSuggestionClick(suggestion)}
                                        style={{ padding: '10px', cursor: 'pointer' }}
                                    >
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="section-head container-md">
                        <p data-inview-showup="showup-translate-left">
                        <strong>MR Xpress Service Locations</strong>
                        <p><strong>Fast, Reliable, and Convenient Mobile Repair—Wherever You Are!</strong></p>
                        <p>At MR Xpress, we bring expert mobile phone repair services right to your doorstep! Whether you're at home, at work, or on the go, our technicians provide on-demand, high-quality repairs across key locations.</p>
                        <p>📍 <strong>Areas We Service</strong></p>
                        <p>We currently offer mobile repair services across major suburbs within a 30km radius of Sydney CBD, including:</p>
                        <p>✅ <strong>Sydney CBD & Inner Suburbs</strong></p>
                        <p style={{ textIndent: '2em' }}>    Alexandria, Surry Hills, Redfern, Waterloo, Newtown, Ultimo, Glebe, Camperdown, Paddington, and surrounding areas.</p>
                        <p>✅ <strong>Eastern Suburbs</strong></p>
                        <p style={{ textIndent: '2em' }}>Bondi, Coogee, Randwick, Maroubra, Bronte, Clovelly, and more.</p>
                        <p>✅ <strong>Western Sydney</strong></p>
                        <p style={{ textIndent: '2em' }}>Parramatta, Blacktown, Bankstown, Auburn, Fairfield, and Liverpool.</p>
                        <p>✅ <strong>North Shore & Northern Suburbs</strong></p>
                        <p style={{ textIndent: '2em' }}>Chatswood, Ryde, Epping, Hornsby, Eastwood, and surrounding locations.</p>
                        <p>✅ <strong>South Sydney & Sutherland Shire</strong></p>
                        <p style={{ textIndent: '2em' }}>Hurstville, Kogarah, Miranda, Sutherland, and nearby areas.</p>
                        <p>🚀 <strong>Why Choose MR Xpress?</strong></p>
                        <p>✔ <strong>On-Demand Repairs</strong> – We come to your location, whether it’s your home, office, or a café.
                        <p>✔ <strong>Quick Turnaround</strong> – Most repairs are completed in under 30 minutes!</p>
                        <p>✔ <strong>Eco-Friendly Service</strong> – We promote sustainability by reducing electronic waste.</p>
                        <p>✔ <strong>Certified Technicians</strong> – Skilled experts ensuring top-quality repairs.</p></p>
                        <p>📞 <strong>Need a Repair? We’re Just a Click Away!</strong></p>
                        <p>Simply book an appointment online, and our mobile repair experts will be there at your preferred time and location.</p>
                        <p>🔹 <Button
                            variant="contained"
                            color="primary"
                            onClick={handleBookNow}
                            style={{ height: '30px' }} // 设置按钮高度
                        >
                            Book Now
                        </Button></p>
                        <p>Bringing fast, convenient, and eco-friendly mobile repairs wherever you are! 🚀</p>
                        <br/>
                        
                        </p>
                    </div>
                </div>
            </section>
            <ContactMap />
            
            <FooterInit />
        </div>
        </>
    )
}