import * as React from 'react';
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Repair from './Repair';
import Api from '../utils/request/http';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import FooterInit from '../components/FooterInit';
import Cookies from 'js-cookie';
import { useNavigate, useBlocker } from 'react-router-dom';
import HeaderInit from '../components/HeaderInit';
import { Helmet } from 'react-helmet-async';

export default function Stepper({setShowSidebar}) {
  
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    
    const [stripePromise, setStripePromise] = useState(null)

    const handleStripPK = () => {
      Api.get('/payment/stripPK', {})
        .then((data) => {
          console.log('data:',data);
          if (data.code !== 1000) {
            enqueueSnackbar(data.msg,  {variant: 'error'})
          } else {
            setStripePromise(loadStripe(data.data.pub_key));
          }
        }
        )
        .catch((error) => {
          console.log('error:',error);
          enqueueSnackbar('Network error',  {variant: 'error'})
        }
        );
    }

    const userToken = Cookies.get('acc_token')
    React.useEffect(() => {
      if (window.fbq) {
        window.fbq('track', 'Book a repair Page');
      }
      if (userToken) {
        handleStripPK()
      } else {
        // navigate('/SignIn')
      }
      // setShowSidebar(0)
    }, []);

    // if (!userToken) {
    //   enqueueSnackbar('Please login first',  {variant: 'error'})
      
    //   console.log('userToken:')
    //   return
    // }

    return (
        <>
          <Helmet>
            <title>Book a Mobile Phone Repair Online | MR Xpress</title>
            <meta name="description" content="Easily book a mobile phone repair online with MR Xpress. We offer battery, screen, and camera replacements, delivered to your home or office." />
            <meta name="keywords" content="services, phone repair, MR Xpress" />
            <meta property="og:title" content="Book a Mobile Phone Repair Online | MR Xpress" />
            <meta property="og:description" content="Easily book a mobile phone repair online with MR Xpress. We offer battery, screen, and camera replacements, delivered to your home or office." />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="MR Xpress"></meta>
            <meta property="og:url" content="https://www.mrxpress.com.au/Repair" />
            <link rel="canonical" href="https://www.mrxpress.com.au/Repair" />
            <meta name="robots" content="index,follow"></meta>
            <meta name="googlebot" content="index,follow"></meta>
            {JSON.stringify({
            "@context": "https://schema.org ",
            "@type": "Service",
            "provider": {
              "@type": "Organization",
              "name": "MR Xpress",
              "url": "https://www.mrxpress.com.au "
            },
            "serviceType": "Mobile Phone Repair",
            "description": "Easily book a mobile phone repair service online for battery, screen, and camera replacements.",
            "areaServed": {
              "@type": "Place",
              "name": "Sydney NSW 2000"
            },
            "availableChannel": {
              "@type": "ServiceChannel",
              "serviceLocation": {
                "@type": "Place",
                "name": "Customer's Location"
              }
            },
            "url": "https://www.mrxpress.com.au/Repair"
            })}
        </Helmet>
          <Elements stripe={stripePromise}>
            <div style={{width: '100%'}}>
            <HeaderInit />
            <Box component="main" sx={{ flexGrow: 1,  pb: 6}} >
                <Repair />
            </Box>
            <FooterInit />
            </div> 
          </Elements>
        </>
    );
}


