// material ui: 5.14
// react-router-dom": "^6.15.0"
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Navbar from './components/Navbar';
import TechSignUp from './pages/TechSignUp';
import UserHome from './pages/UserHome';
import MultiStepForm from './pages/Stepper';
import { SnackbarProvider } from 'notistack';
import OrderLst from './pages/OrderLst';
import OrderDetail from './pages/OrderDetail';
import SideBar from './components/SideBar';
import Box from '@mui/material/Box';
import OrderRating from './pages/OrderRating';
import ForgotPassword from './pages/ForgotPwd';
import ResetPwd from './pages/ResetPwd';
import ContactUs from './pages/ContactUs';
import Footer from './components/Footer';
import AboutUs from './pages/AboutUs';
import Career from './pages/Career';
import Services from './pages/Services';
import LeavePageTip from './components/LeavePageTip';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundPolicy from './pages/RefundPolicy';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from "react-router-dom";
import Layout from './components/Layout';
import Policy from './pages/Policy';
import TechnicianIntro from './pages/TechnicianIntro';
import PromotionCode from './pages/PromotionCode';
import RepairInfo from './pages/RepairInfo';
import LocationService from './pages/LocationService';
import NotFound from './pages/NotFound';

const theme = createTheme();

export default function App() {

  const [showSidebar, setShowSidebar] = React.useState(0);

  const [isIndex, setIsIndex] = React.useState(0);

  const [selectedIndex, setSelectedIndex] = React.useState(1)

  const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={<Layout isIndex={isIndex} />} errorElement={<NotFound />}>

      <Route index element={<Home setShowSidebar={setShowSidebar} setIsIndex={setIsIndex} />} />
      <Route path="Repair" element={<><MultiStepForm setShowSidebar={setShowSidebar} /> </>} />
      <Route path="SignIn" element={<SignIn setShowSidebar={setShowSidebar} />} />
      <Route path="ForgotPwd" element={<ForgotPassword setShowSidebar={setShowSidebar} />} />
      <Route path="ResetPwd/:token" element={<ResetPwd setShowSidebar={setShowSidebar} />} />
      <Route path="SignUp" element={<SignUp setShowSidebar={setShowSidebar} />} />
      <Route path="TechSignUp" element={<TechSignUp setShowSidebar={setShowSidebar} />} />
      <Route path="ContactUs" element={<ContactUs />} />
      <Route path="AboutUs" element={<AboutUs />} />
      <Route path="Career" element={<Career />} />
      <Route path="Services" element={<Services />} />
      <Route path="TermsOfService" element={<TermsOfService />} />
      <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="RefundPolicy" element={<RefundPolicy />} />
      <Route path="PromotionCode" element={<PromotionCode />} />
      <Route path="UserHome" element={<UserHome setShowSidebar={setShowSidebar} setSelectedIndex={setSelectedIndex} />} />
      <Route path="OrderLst/:page?" element={<OrderLst setShowSidebar={setShowSidebar} setSelectedIndex={setSelectedIndex} />} />
      <Route path="OrderDetail/:orderId" element={<OrderDetail />} />
      <Route path="OrderRating/:orderId" element={<OrderRating />} />
      <Route path="Locations/:locName" element={<LocationService />} />
      <Route path="Locations" element={<LocationService />} />
      <Route path="RepairInfo/:repairInfo" element={<RepairInfo />} />
      <Route path="TechnicianIntro/:technicianId" element={<TechnicianIntro />} />
    </Route>
  ));

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Box sx={{}}>
          {/* <BrowserRouter > */}
          <RouterProvider router={router}>
            {showSidebar === 1 ? <SideBar selectedIndex={selectedIndex} /> : null}
          </RouterProvider>
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
}