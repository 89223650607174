import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { ButtonGroup } from '@mui/material';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {isStringEmpty} from '../utils/isStringEmpty.js';
import {isEmailValid} from '../utils/isEmailValid.js';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import TechApi from '../utils/request/techHttp.js';
import config from '../config.js';
import { Link } from 'react-router-dom';
import AddressAutoComplete from '../components/AddressAutoComplete.js';
import ReCAPTCHA from 'react-google-recaptcha';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';
import { isPhoneValid } from '../utils/isPhoneValid.js';
import { Helmet } from 'react-helmet-async';

const ReCAPTCHA_SITE_KEY = config.recaptcha_key

const defaultTheme = createTheme();

const GrayButton = styled(Button)({
  color: '#ccc',
  borderTopColor: '#ccc',
  borderLeftColor: '#ccc',
  borderBottomColor: '#ccc',
});

const countTime = 120;

export default function TechSignUp({setShowSidebar}) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [errors, setErrors] = React.useState({});

  const [formData, setFormData] = React.useState({});

  const [captchaValue, setCaptchaValue] = React.useState(null);

  const recaptchaRef = React.useRef();

  const [showPassword, setShowPassword] = React.useState(false);

  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const [countdown, setCountdown] = React.useState(countTime);

  const handleVerification = () => {
    if (!formData.email) {
      enqueueSnackbar('Please input your email', {variant: 'error'})
      return;
    }
    // Disable the button
    setButtonDisabled(true);

    TechApi.post('/api/technician/sendCode', {email: formData.email})
      .then(res => {
        setButtonDisabled(false);
        if (res.code !== 1000) {
          enqueueSnackbar(res.msg,  {variant: 'error'})
          return;
        }
        enqueueSnackbar('Verification code has been sent to your email',  {variant: 'success'})
        startCountdown();
      })
      .catch(e => {
        setButtonDisabled(false);
        enqueueSnackbar('Network error',  {variant: 'error'})
      })
    
  };

  const startCountdown = () => {
    let timer = countdown;
    const countdownInterval = setInterval(() => {
      if (timer > 0) {
        timer -= 1;
        setCountdown(timer);
      } else {
        // Enable the button when the countdown reaches 0
        setButtonDisabled(false);
        clearInterval(countdownInterval);
        setCountdown(countTime);
      }
    }, 1000);
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let errMsg = {};
    
    errMsg.firstName = isStringEmpty(formData.first_name) ? 'First Name is required' : '';

    errMsg.lastName = isStringEmpty(formData.last_name) ? 'Last Name is required' : '';
    
    errMsg.email = isStringEmpty(formData.email) ? 'Email is required' : '';
    if (!errMsg.email) {
        errMsg.email = !isEmailValid(formData.email) ? 'Email is not valid' : '';
    }

    errMsg.code = isStringEmpty(formData.code) ? 'Verification is required' : '';

    errMsg.phone = isStringEmpty(formData.mobile) ? 'Phone number is required' : '';
    if (!errMsg.phone) {
      errMsg.phone = !isPhoneValid(formData.mobile) ? 'Phone number is not valid' : '';
    }

    errMsg.password = isStringEmpty(formData.password) ? 'Password is required' : '';

    setErrors({...errMsg});

    return Object.values(errMsg).every(item => item === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!captchaValue) {
      enqueueSnackbar('Please verify google reCaptcha',  {variant: 'error'})
      return;
    }

    console.log(validateForm());

    if (!validateForm()) {
      enqueueSnackbar('Please fill in the form correctly',  {variant: 'error'})
      return;
    } 
    const data = new FormData(event.currentTarget);

    TechApi.post('/api/technician/register', data)
      .then((data) => {
        console.log('User created:', data);
        if (data.code !== 1000) {
          recaptchaRef.current.reset();
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          enqueueSnackbar('User created successfully',  {variant: 'success'})
          window.location.href = config.tech_host + '/technician';
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  };

  React.useEffect(() => {
    setShowSidebar(0)
    
  }, []);

  return (
    <>
    <Helmet>
      <title>Sign up to become a MR Xpress technician | Sign up</title>
      <meta name="description" content="Sign up to become a MR Xpress technician." />
      <meta name="keywords" content="technician, sign up, register, phone repair, MR Xpress" />
      <meta property="og:title" content="Sign up to become a MR Xpress technician | Sign up" />
      <meta property="og:description" content="Sign up to become a MR Xpress technician." />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="MR Xpress"></meta>
      <meta property="og:url" content="https://www.mrxpress.com.au/TechSignUp" />
      <link rel="canonical" href="https://www.mrxpress.com.au/TechSignUp" />
      <meta name="robots" content="index,follow"></meta>
      <meta name="googlebot" content="index,follow"></meta>
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Sign Up",
        "description": "Sign up to become a MR Xpress technician",
        "url": "https://www.mrxpress.com.au/TechSignUp"
      })}
    </Helmet>
    <Box component="main" sx={{ flexGrow: 1, mb: 5}}>
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            // marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#EA3A13' }}>
            <LockOutlinedIcon />
          </Avatar>
          {/* <Typography component="h1" variant="h5">
            Sign up
          </Typography> */}
          <ButtonGroup aria-label="medium secondary button group">
            <GrayButton component={Link}  to="/SignUp" key="Customer">Customer</GrayButton>
            <Button component={Link}  to="/TechSignUp" key="Technician" color='primary'>Technician</Button>
          </ButtonGroup>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.firstName ? true : false}
                  helperText={errors.firstName}
                  onChange={handleChange}
                  autoComplete="given-name"
                  name="first_name"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.lastName ? true : false}
                  helperText={errors.lastName}
                  onChange={handleChange}
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="last_name"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.email ? true : false}
                  helperText={errors.email}
                  onChange={handleChange}
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  error={errors.code ? true : false}
                  helperText={errors.code}
                  onChange={handleChange}
                  name="code"
                  required
                  fullWidth
                  id="code"
                  label="Verification Code"
                  autoFocus
                />
              </Grid>
              <Grid item xs={4} sm={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ height: '80%', fontSize:'12px'}}
                  disabled={buttonDisabled}
                  onClick={handleVerification}
                >
                  {buttonDisabled ? `Resend in ${countdown} seconds` : 'Send code'}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.phone ? true : false}
                  helperText={errors.phone}
                  onChange={handleChange}
                  required
                  fullWidth
                  id="phone"
                  label="Phone"
                  name="mobile"
                  autoComplete="phone"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.password ? true : false}
                  helperText={errors.password}
                  onChange={handleChange}
                  required={true}
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handlePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  error={errors.address ? true : false}
                  helperText={errors.address}
                  onChange={handleChange}
                  // required={true}
                  fullWidth
                  name="address"
                  label="Address"
                  type="address"
                  id="address"
                  autoComplete="new-address"
                />
              </Grid> */}
              <Grid item xs={12}>
                <AddressAutoComplete />

              </Grid>
              <Grid item xs={12}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={ReCAPTCHA_SITE_KEY}
                onChange={handleCaptchaChange}
              />
              </Grid>
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/SignIn" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
    </ThemeProvider>
    </Box>
    </>
  );
}