
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/custom_styles.css';

export default function Footer({ isIndex }) {



    return (
        <footer className={isIndex ? "footer" : "footer alt-bg"} >
            <div className="container only-xs-text-justify-center" style={{ marginTop: '20px' }}>
                <div className="solid-section">
                    <div className="row cols-md">
                        <div className="sm-col-3">
                            <div className="footer-logo">
                                <img src="/assets/images/parts/mrxpress-logo.svg" alt="MRXpress" />
                            </div>

                        </div>
                        <div className="sm-col-8 sm-push-1">
                            <div className="row cols-md">

                                <div className="sm-col-4">
                                    <div className={isIndex ? "footer-title alt-color text-upper" : "footer-title text-upper"}>Contacts</div>
                                    <div className="footer-text"><i className="fas fa-question-circle"></i> &nbsp;<a href='mailto:help@mrxpress.com.au' style={{ 
                                        textDecoration: 'none', 
                                        color: 'inherit',
                                        '&:hover': {
                                          textDecoration: 'none',
                                          color: 'inherit'
                                        }
                                      }}>help@mrxpress.com.au</a></div>
                                    <div className="footer-text"><i className="fas fa-phone-alt"></i> &nbsp;  
                                    <a href='tel:+610411937372' style={{ 
                                        textDecoration: 'none', 
                                        color: 'inherit',
                                        '&:hover': {
                                        textDecoration: 'none',
                                        color: 'inherit'
                                        }
                                    }}>0411 93 73 72</a> </div>
                                </div>
                                <div className="sm-col-4">
                                    <div className={isIndex ? "footer-title alt-color text-upper" : "footer-title text-upper"}>Socials</div>
                                    <div className="cols-list socials cols-sm inline-block">
                                        <a href="https://www.facebook.com/MobileRepairXpress" className="list-item"><i className="fab fa-facebook-f"
                                            aria-hidden="true"></i></a>
                                        <a href="https://www.instagram.com/mrxpress.admin" className="list-item"><i className="fab fa-instagram"
                                            aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="sm-col-4">
                                    <div className={isIndex ? "footer-title alt-color text-upper" : "footer-title text-upper"}>Legal</div>
                                    <ul className={isIndex ? "footer-links-light" : "footer-links-dark"}>
                                        <li><Link to="/TermsOfService">Terms of Service</Link></li>
                                        <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>
                                        <li><Link to="/RefundPolicy">Refund and Cancellation Policy</Link></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyrights text-center top-separator ins-md">Copyright &copy; 2024 <b>MR Xpress</b>. All Rights
                    Reserved.</div>
            </div>
        </footer>
    )
}