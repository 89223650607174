import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <>
    <Navbar isIndex={0} />
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    }}>
      <h1 style={{ fontSize: '4rem', margin: '20px 0' }}>404</h1>
      <h2>Page Not Found</h2>
      <p>Sorry, the page you’re looking for can’t be found.</p>
      <Button 
        variant="contained" 
        onClick={() => navigate('/')}
        style={{ marginTop: '20px' }}
      >
        Back to Home
      </Button>
    </div>
    <Footer isIndex={0} />
    </>
  );
}